<template lang="pug">
v-row
  v-col
    v-data-table(class="elevation-1" :headers="headers" :items="reports" :loading="loading" loading-text="Загружается... Пожалуйста подождите")
      template(v-slot:item.date="{ item }") {{ dateFormat(item.date) }}
      template(v-slot:item.status="{ item }")
        Export(:id="item.id")
        v-btn(rounded small @click="view(item.id)")
          v-icon mdi-card-search

</template>


<script>
    import { reportService, networkService } from '@/_services'
    import Export from './Export'
    import { mapMutations, mapState } from "vuex";

    export default {
        props: ['project', 'id', 'start', 'end', 'cities'],
        components: {
            Export
        },
        data: () => ({
            headers: [
              { text: 'Адрес', value: 'address' },
              { text: 'Статус', value: 'status' },
              { text: 'Время', value: 'date' },
              { text: 'Мерчендайзер', value: 'user' }
            ],
            reports: [],
            loading: false
        }),
        mounted() {
            if (!this.selectedNetwork) this.loadNetwork()
            else this.setAppBarTitle(this.selectedNetwork.name)
            this.load()
        },
        computed: {
            ...mapState("network", ["selectedNetwork"])
        },
        methods: {
            ...mapMutations("network", ["setSelectedNetwork"]),
            ...mapMutations(["setAppBackLink"]),
            ...mapMutations(['setAppBarTitle']),
            loadNetwork() {
                this.loadingHead(networkService.loadNetwork, this.id, data => {
                    this.setSelectedNetwork(data)
                    this.setAppBarTitle(data.name)
                    this.load()
                })
            },
            load() {
                this.setAppBackLink('/report/download')
                this.loading = true
                reportService.loadByNetwork(this.project, this.id, this.start, this.end, this.cities).then(data => {
                  this.reports = data;
                }).finally(() => this.loading = false)
            },
            dateFormat(dateString) {
                let date = new Date(Date.parse(dateString))
                return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
            },
            view(id) {
                this.$router.push({ name: 'report', params: { id: id, network: `${this.id}?start=${this.start}&end=${this.end}` } })
            }
        }
    }
</script>